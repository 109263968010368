import React from "react";
//import stayConnectedImage from '../assets/StayConnectedVector.svg'
import img1 from "../assets/StayConnected1.svg";
import img2 from "../assets/StayConnected2.svg";
import img3 from "../assets/StayConnected3.svg";
import img4 from "../assets/StayConnected4.svg";
import phone from "../assets/ShareEarnPhone.svg";

//Components
import StayConnectedBox from "../components/StayConnectedBox";

//Via is the midpoint for the gradient
const StayConnected = () => {
  return (
    <div
      name="Stay Connected"
      className=" w-full md:p-4 mx-auto flex flex-col justify-center bg-black portrait:pb-5"
    >
      <div className="bg-[url('../public/StayConnectedBackground.svg')] bg-cover">
        <div className="pb-8 md:flex md:flex-row md:justify-center p-4 md:p-0">
          <div className="flex flex-col justify-center ">
            <p className="font-bold lg:text-5xl md:text-4xl text-2xl text-white">
              Stay Connected
            </p>
            <p className="lg:text-xl md:text-xl text-base mt-5 text-gray-400 pb-5">
              and earn for sharing your network
            </p>
          </div>
        </div>
        <div className="w-3/4 mx-auto grid portrait:grid-cols-2 landscape:grid-cols-4 md:grid-cols-4 portrait:gap-8 landscape:gap-2 md:landscape:gap-8 px-5 sm:px-0 ">
          <StayConnectedBox srcImage={img1} />
          <StayConnectedBox srcImage={img2} />
          <StayConnectedBox srcImage={img3} />
          <StayConnectedBox srcImage={img4} />
        </div>
      </div>
    </div>
  );
};

export default StayConnected;
