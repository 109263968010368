import React from "react";
//import planetImage from '../assets/PlanetVector4.svg'
import googlePlay from "../assets/GooglePlayVector.svg";
import appleStore from "../assets/AppStoreVector.svg";

//Via is the midpoint for the gradient
const Home = () => {
  return (
    <div
      name="Home"
      className="flex flex-col justify-center p-32 md:pt-80 h-1/2 items-center bg-gradient-to-b via-black from-black to-gray-800"
    >
      <a href="https://xmesh.org/XOneFi_Whitepaper.pdf" target="_blank" rel="noreferrer">
        <h1 id="home" className="text-center md:text-9xl sm:text-7xl font-bold text-white" style={{ fontFamily: "Ubuntu, sans-serif" }}>
          XOnefi
        </h1>
      </a>
      <p className="text-center text-gray-500 py-4 text-5xl">
        Equitable Internet For All
      </p>
      <div className="flex flex-row justify-center pt-8">
        <a href="https://play.google.com/store/apps/details?id=com.onefi.XOneFiApp" target="_blank" rel="noopener noreferrer">
          <button>
            <img src={googlePlay} alt="my profile" className="hover:scale-105" />
          </button>
        </a>
        <button className="px-4">
          <img src={appleStore} alt="my profile" className="hover:scale-105" />
        </button>
      </div>
    </div>
  );
};

export default Home;
