import React from "react";

function TextBox({ headerText, bodyText }) {
  return (
    <div className="pb-8">
      <p className="lg:text-5xl md:text-4xl text-2xl font-bold inline border-b-4 border-gray-500">
        {headerText}
      </p>
      <p className="md:text-xl text-base mt-5 text-gray-400">{bodyText}</p>
    </div>
  );
}

export default TextBox;
