import React from "react";
import phone from "../assets/ShareEarnPhone.svg";

//Components
import TextBox from "../components/TextBox";

function ShareAndEarn() {
  return (
    <div
      name="Share and Earn"
      className="w-full bg-black text-white flex 
      md:flex-row sm:flex-col landscape:flex-row md:items-center md:justify-center p-4"
    >
      <TextBox
        headerText={"Share and Earn"}
        bodyText={
          "Xonefi Provider shares wireless Internet access and earns XFI tokens."
        }
      />
      <img
        className="object-contain h-1/4 w-1/4"
        src={phone}
        alt="my profile"
      />
    </div>
  );
}

export default ShareAndEarn;
