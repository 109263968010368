import React from "react";
import routers2 from "../assets/Router Logo.svg";
import connect2 from "../assets/ConnectLogo.svg";
import status2 from "../assets/StatusLogo.svg";
import wallet2 from "../assets/walletLogo.svg";

//Components
import SeeThroughBox from "../components/SeeThroughBox";
import TextBox from "../components/TextBox";

//@Todo change text to Ubuntu
const OnefiFeatures = () => {
  return (
    <div
      name="Features"
      className="bg-gradient-to-b from-gray-800 to-black w-full text-white md:h-screen"
    >
      {/*Name is required for the smoothe scroll*/}
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full bg-[url('../public/PlanetVectorPublic.svg')] bg-contain bg-no-repeat bg-center">
        <TextBox
          headerText={"Features"}
          bodyText={
            "Xonefi unifies free, paid, enterprise, pay-for-data, pay-for-time, and private wireless connections under a decentralized cross-domain authentication protocol that uses a single decentralized identity. Xonefi uses a smart contract as a guarantor of protocols for authentication and wireless session management."
          }
        />
        <div className="text-white grid portrait:grid-cols-2 landscape:grid-cols-4 md:grid-cols-4 portrait:gap-8 landscape:gap-2 md:landscape:gap-8 px-5 sm:px-0">
          <SeeThroughBox
            srcImage={routers2}
            headerText={"Search for routers"} 
            bodyText={"Automatically find the best access point in the region"}
            className="text-white"
          />
          <SeeThroughBox
            srcImage={connect2}
            headerText={"Connect"}
            bodyText={"Get connected instantly"}
          />
          <SeeThroughBox
            srcImage={status2}
            headerText={"Status"}
            bodyText={"Check your connectivity status anytime"}
          />
          <SeeThroughBox
            srcImage={wallet2}
            headerText={"Wallet"}
            bodyText={"Keep tokens safe"}
          />
        </div>
      </div>
    </div>
  );
};

export default OnefiFeatures;
