import React from "react";
import Logo from "../assets/HowItWorksLogo-v3.svg";
import createYourAccount from "../assets/CreateYourAccountLogo.svg";
import ConnectBank from "../assets/ConnectBankAccountLogo.svg";
import ConfigureRouter from "../assets/ConfigureYourRouterLogo.svg";
import RectangleLogoAndText from "../components/RectangleLogoAndText";

//Via is the midpoint for the gradient
const HowItWorks = () => {
  return (
    <div
      name="How It Works"
      className="w-full bg-gradient-to-b via-black
     from-black to-gray-800 text-white md:pt-48 pt-5 flex flex-row justify-evenly"
    >
      <div
        className="max-w-screen-lg mx-auto items-center justify-center
            h-full flex flex-row"
      >
        <div className="md:p-5 p-4 portrait:p-2 md:bg-black md:shadow-2xl md:shadow-blue-700 md:rounded-lg md:flex hidden mr-5">
          <img src={Logo} alt="my profile" className="md:h-96" />
        </div>

        <div className="flex flex-col portrait:pl-4 ml-5">
          <p className="md:text-4xl text-2xl font-bold underline decoration-4 underline-offset-4 decoration-gray-500">
            How It Works
          </p>
          <br />
          <RectangleLogoAndText
            srcImage={createYourAccount}
            headerText={"Create Your Account"}
            bodyText={"Your account and personal identity are guaranteed safe."}
          />
          <br />
          <RectangleLogoAndText
            srcImage={ConnectBank}
            headerText={"Connect Bank Account"}
            bodyText={"Connect the bank account to start transactions."}
          />
          <br />
          <RectangleLogoAndText
            srcImage={ConfigureRouter}
            headerText={"Configure your router"}
            bodyText={
              "Configure your router and set your prices for sharing your network."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
