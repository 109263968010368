import React from "react";
import "../components/NewsArticle"
import NewsArticle from "../components/NewsArticle";
import image from "../assets/UnicefXoneFiArticleImage.jpg"

//Via is the midpoint for the gradient
const News = () => {
  return (
    <div
      name="News"
      className="flex flex-col items-center h-full w-full bg-gray-800 text-white justify-center md:pt-0 portrait:pt-8 landscape:pt-56 landscape:pb-20 md:landscape:pt-24 "
    >
      <h1 className="md:text-4xl text-2xl font-bold justify-center md:pt-5 text-white underline decoration-4 underline-offset-4 decoration-gray-500 mb-5 pb-5">
        News and Updates
      </h1>
      <div className="flex flex-row min-w-full px-5">
      <NewsArticle 
        imageSrc={image} 
        headerText={"XOneFi: Equitable, affordable Internet access for remote and underserved areas"} 
        bodyText={"XMesh was created to provide equitable Internet access for all communities, especially underserved ones around the globe, using blockchain technologies. The first challenge for XMesh was focusing on the market in Nigeria, the most populous country in Africa yet....."} 
        url={'https://www.unicefinnovationfund.org/broadcast/updates/xonefi-equitable-affordable-internet-access-remote-and-underserved-areas'}
        />
        
      </div>
    </div>
  );
};

export default News;
