import React from "react";
import TextBox from "../components/TextBox";
import FirmwareTable from "../components/FirmwareTable";

const FirmwareOption = ({VersionText}) => {
    return(
        <button onClick={()=>{alert("Firmware Download Clicked")}} className="bg-gray-400 flex flex-row w-full justify-center border-b-2 border-black p-1">
            <p>{VersionText}</p>
        </button>
    )
}

const FirmwareDownload = () => {
    return (
            <div
              className="w-screen bg-black text-white flex h-screen
              md:flex-row sm:flex-col landscape:flex-row md:items-center md:justify-evenly p-4"
            >
              <TextBox
                headerText={"Download Our Firmware"}
                bodyText={
                  "Our Firmware enables your router to become an XOneFi router."
                }
              />
              <div className="text-xl w-1/2 bg-gray-400">
                <FirmwareTable/>
              </div>
            </div>
          );

}

export default FirmwareDownload;