import { Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import NavBar from "./screens/NavBar";
import SocialLinks from "./screens/SocialLinks";
import Token from "./screens/Token";
import OnefiFeatures from "./screens/OnefiFeatures";
import StayConnected from "./screens/StayConnected";
import HowItWorks from "./screens/HowItWorks";
import ConnectWallet from "./screens/ConnectWallet";
import News from "./screens/News";
import ShareAndEarn from "./screens/ShareAndEarn";
import TermsAndConditions from "./screens/TermsAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import NotFound404 from "./screens/NotFound404";
import FirmwareDownload from "./screens/FirmwareDownload";
import Whitepaper from "./screens/Whitepaper";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <NavBar />
            <Home />
            <OnefiFeatures />
            <Token />
            <StayConnected />
            <ShareAndEarn />
            <HowItWorks />
            <ConnectWallet />
            <News />
            <SocialLinks />
          </>
        }
      />
      <Route path="/Whitepaper" element={<Whitepaper/>} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="XOneFi" element={<FirmwareDownload/>} />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
}

export default App;
