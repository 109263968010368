import React from "react";
//Just lets you import icons
import { FaBars, FaTimes } from "react-icons/fa";
import { useState } from "react";
import logo from "../assets/OnefiLogoVector.svg";
import { Link } from "react-scroll";

const NavBar = () => {
  //fixed will fix the navigation bar on top so it will not move even if you scroll
  //text-white makes the text in the h1 white
  //bg-black makes the background black
  //px - padding on x axis
  //ml- margin left of blank
  //ul will have all of our link items. ul stands for unordered list
  //hover:scale-105 duration-200   -hover when above the list item, and duration is how long it should take to scale to 105

  //default state is false
  const [nav, setNav] = useState(false);

  const links = [
    { id: 1, link: "Home" },
    { id: 2, link: "Whitepaper" , url: '/Whitepaper'},
    { id: 3, link: "Features" },
    { id: 4, link: "Token" },
    { id: 5, link: "Share and Earn" },
    { id: 6, link: "How It Works" },
    { id: 7, link: "News" },
  ];
  return (
    <div
      className="flex justify-between items-center h-20 text-white 
    bg-black fixed object-contain px-4 w-screen"
    >
      {/*"shadow-md shadow-gray-600 rounded-lg - old css"*/}
      <div>
        <a href="/" id="logo" alt="XMesh - go to homepage">
          <img src={logo} alt="" className="object-contain" />
        </a>
      </div>
      {/*This iterates through our links array and creates elements for them*/}
      <ul className="hidden md:flex landscape:flex">
        {links.map(({ id, link, url }) => (
          <li
            key={id}
            className="cursor-pointer md:px-4 px-1 capitalize font-medium md:text-base text-xs text-gray-500
               hover:scale-105 duration-200 "
          >
            {url ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {link}
              </a>
            ) : (
              <Link to={link} smooth duration={500}>
                {link}
              </Link>
            )}
          </li>
        ))}
      </ul>

      {/*md-hidden means hide if the screen size is medium*/}
      {/*pr-4*/}
      <div
        onClick={() => setNav(!nav)}
        className="cursor-pointer z-40 text-gray-500 md:hidden landscape:hidden"
      >
        {/*What this is doing is using the nav state. If it is clicked the nav state is cha
            to the opposite of what it was. It switches from the x icon to the bars icon for mobile*/}
        {nav ? (
          <FaTimes size={30} className="object-contain" />
        ) : (
          <FaBars size={30} className="object-contain" />
        )}
      </div>

      {/*This means show only is nav is true*/}
      {nav && (
        <ul className="flex flex-col justify-center items-center w-screen-50 absolute top-0 right-0 h-screen-50 bg-gradient-to-b from-black to-gray-800 text-gray-500 z-30 outline outline-4">
          {/*px-4*/}
          {links.map(({ id, link }) => (
            <li
              key={id}
              className="cursor-pointer capitalize py-2 text-2xl z-11"
            >
              <Link
                onClick={() => setNav(!nav)}
                to={link}
                smooth
                duration={500}
              >
                {link}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavBar;
