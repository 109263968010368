import React from "react";
import TextBox from "../components/TextBox";

const Token = () => {
  return (
    <div
      name="Token"
      className="bg-black text-white pb-32 p-4 flex flex-col justify-center w-full portrait:pb-5"
    >
      <div className="w-1/2  mx-auto portrait:w-full">
        <TextBox
          headerText={"Xonefi Token"}
          bodyText={
            "Xonefi lets the Client pay automatically from a consolidated decentralized account using predetermined secure policies and a fairly-collateralized ERC-20 token, XFI token. Xonefi further uses cryptographic endorsements for trustworthy accounting."
          }
        />
      </div>
    </div>
  );
};

export default Token;
