import React from "react";

/**
 *
 * @returns A Component for the see through boxes with logo and text found in OnefiFeatures Screen
 */
function SeeThroughBox({ srcImage, headerText, bodyText }) {
  return (
    <div className="shadow-md shadow-gray-600 pl-5 pt-5 pb-6 flex flex-col rounded-md duration-200 hover:scale-105">
      <img
        src={srcImage}
        alt=""
        className="rounded-md duration-200 hover:scale-105 object-scale-down h-14 w-14"
      />
      <h1 className="text-white font-bold pt-5">{headerText}</h1>
      <p className="pt-2 md:text-base text-xs text-gray-400 pr-5">{bodyText}</p>
    </div>
  );
}

export default SeeThroughBox;
