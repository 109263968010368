import React from 'react'

function NewsArticle({imageSrc, headerText, bodyText, url}) {
  return (
        <div class="max-w-sm rounded-2xl overflow-hidden shadow-lg bg-black">
            <a href={url} target="_blank">
    <img class="w-full" src={imageSrc} alt="News Article Image"/>
    <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">{headerText}</div>
        <p class="text-gray-700 text-base">
        {bodyText}
        </p>
    </div>
    </a>
    </div>
  )
}

export default NewsArticle