import React from "react";

function StayConnectedBox({ srcImage }) {
  return (
    <div className="shadow-md shadow-gray-600 rounded-2xl">
      <div className="flex flex-col rounded-md duration-200 hover:scale-105">
        <img
          src={srcImage}
          alt=""
          className="rounded-md duration-200 hover:scale-105"
        />
      </div>
    </div>
  );
}

export default StayConnectedBox;
