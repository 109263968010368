import React from 'react'
import { useState } from 'react';

const example_data = [
    
    {
     "id": 1,
     "brand": "ROUTER BRAND 1",
     "model": "MODEL",
     "firmware": "LINK"
    },
    {
        "id": 2,
        "brand": "ROUTER BRAND 2",
        "model": "MODEL",
        "firmware": "LINK"
       },
       {
        "id": 3,
        "brand": "ROUTER BRAND 3",
        "model": "MODEL",
        "firmware": "LINK"
       },
       {
        "id": 4,
        "brand": "ROUTER BRAND 4",
        "model": "MODEL",
        "firmware": "LINK"
       },
       {
        "id": 5,
        "brand": "ROUTER BRAND 5",
        "model": "MODEL",
        "firmware": "LINK"
       },
       {
        "id": 6,
        "brand": "ROUTER BRAND 6",
        "model": "MODEL",
        "firmware": "LINK"
       },
   ]

export default function FirmwareTable() {
    const [columns, setColumns] = useState([
        { label: "Brand", accessor: "brand" },
        { label: "Model", accessor: "model" },
        { label: "Firmware", accessor: "firmware" }
       ]);
    
    const [dummy_data, setData] = useState(example_data);

    const SortViaHeader = (accessor) => {
        alert("Sort Clicked")

        const sorted_data = dummy_data.sort((a, b) => {
            if (a.brand < b.brand) {
              return -1;
            }
          });

        console.log("sorted data : ")
        console.log(sorted_data)

        setData(sorted_data);
        
       };
    
  return (
    <>
    <table className="flex flex-col p-1">
        <caption>
        Firmware Downloads
        </caption>
        <TableHeader columns={columns} sortFunction={(input)=>{SortViaHeader(input)}}/>
        <TableBody columns={columns} firmware_data={dummy_data}/>
    </table>
  </>

  )
}

function TableHeader({columns, sortFunction}){
    return(
        <thead className='border-y-2 border-black p-1'>
            <tr className='flex flex-row justify-between px-3'>
                {columns.map(({ label, accessor }) => {
                return <th classname="" key={accessor} onClick={() => sortFunction(accessor)} >{label}</th>;
                })}
            </tr>
        </thead>
    )
}

function TableBody({columns, firmware_data}){

    return(
        <tbody>
   {firmware_data.map((data) => {
    return (
     <tr className='flex flex-row justify-between px-3' key={data.id}>
      {columns.map(({ accessor }) => {
       const tData = data[accessor] ? data[accessor] : "——";
       return <td classname="" key={accessor}>{tData}</td>;
      })}
     </tr>
    );
   })}
  </tbody>
    )
}