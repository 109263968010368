import React from "react";
import phone from "../assets/ConnectWalletPhone.svg";
import googlePlay from "../assets/GooglePlayVector.svg";
import appleStore from "../assets/AppStoreVector.svg";
import logos from "../assets/Connect Wallet Token Logos.svg";

//Via is the midpoint for the gradient
const ConnectWallet = () => {
  return (
    <div
      name="ConnectWallet"
      className="h-screen w-full bg-gray-800 text-white md:pt-40 portrait:pt-0 md:landscape:pt-40 landscape:pt-96 md:pb-1"
    >
      <div
        className="max-w-screen-lg md:w-5/6 mx-auto items-center justify-center
            h-full flex flex-row md:bg-black md:shadow-2xl md:shadow-blue-700 md:rounded-lg"
      >
        <img
          src={phone}
          alt="my profile"
          className="mx-auto md:w-full md:h-96 md:flex hidden"
        />

        <div className="flex flex-col justify-center h-full z-10 pr-10">
          <img src={logos} alt="" className="rounded-2xl mx-auto w-full " />
          <h2 className="text-center md:text-5xl text-2xl font-bold text-white md:pt-5 pt-5">
            Connect Your Wallet
          </h2>
          <div className="flex flex-row justify-center pt-10 ">
            <button>
              <img src={googlePlay} alt="my profile" />
            </button>
            <button className="pl-6">
              <img src={appleStore} alt="my profile" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
