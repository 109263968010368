import React from "react";
import {
  /*FaGithub, FaLinkedin,*/ FaTwitter,
  FaYoutube,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import logo from "../assets/OnefiLogoVector.svg";

const SocialLinks = () => {
  return (
    <div className="flex flex-row bg-black justify-between">
      <div className="md:pl-16">
        <img src={logo} alt="" className="hidden md:flex" />
      </div>
      <div className="flex flex-col">
        {/**The links to the socials */}
        <div className="flex flex-row justify-end  pb-5 pr-10">
          <li
            className=" flex flex-row justify-between items-center w-15 h-14 px-4
                     hover:rounded-md duration-300"
          >
            <a
              href={"mailto:support@xmesh.org"}
              className="flex justify-between items-end w-full text-white"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter size={30} />
            </a>
          </li>
          {/**<li className=" flex flex-row justify-between items-center w-15 h-14 px-4
                     hover:rounded-md duration-300">
                <a href={"https://github.com"} className='flex justify-between items-end w-full text-white' target='_blank' rel="noreferrer">
                <FaGithub size={30}/>
                </a>
            </li>*/}
          <li
            className=" flex flex-row justify-between items-center w-15 h-14 px-4
                     hover:rounded-md duration-300"
          >
            <a
              href={"mailto:support@xmesh.org"}
              className="flex justify-between items-end w-full text-white"
              target="_blank"
              rel="noreferrer"
            >
              <HiOutlineMail size={30} />
            </a>
          </li>
          <li
            className=" flex flex-row justify-between items-center w-15 h-14 px-4
                     hover:rounded-md duration-300"
          >
            <a
              href={"mailto:support@xmesh.org"}
              className="flex justify-between items-end w-full text-white"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube size={30} />
            </a>
          </li>
          <li
            className=" flex flex-row justify-between items-center w-15 h-14 px-4
                     hover:rounded-md duration-300"
          >
            <a
              href={"mailto:support@xmesh.org"}
              className="flex justify-between items-end w-full text-white"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook size={30} />
            </a>
          </li>
          <li
            className=" flex flex-row justify-between items-center w-15 h-14 px-4
                     hover:rounded-md duration-300"
          >
            <a
              href={"mailto:support@xmesh.org"}
              className="flex justify-between items-end w-full text-white"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram size={30} />
            </a>
          </li>
        </div>
        {/**The copyright */}
        <div className="flex flex-row justify-end pr-16">
          <h1 className="text-white pb-10">XMesh © 2024</h1>
        </div>
      </div>
    </div>
  );
};

export default SocialLinks;
