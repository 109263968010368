import React from "react";
import './Whitepaper.css'

const Whitepaper = () => {
    return (
        <>
            {/* <h2 id="contents">Contents</h2>
            <ul>
                <li>
                    1 Introducing XOneFi
                    <ul>
                        <li>1.1 Our Mission</li>
                        <li>1.2 Solving The Problem of Internet Inequality</li>
                        <li>1.3 XOneFi Solution Overview</li>
                    </ul>
                </li>
                <li>
                    2 Technical Architecture
                    <ul>
                        <li>2.1 Hardware Components</li>
                        <li>2.2 Software Components</li>
                        <li>2.3 Ethereum and Layer-2 Scaling Solution</li>
                        <li>2.4 WiFi Mesh Networking</li>
                    </ul>
                </li>
                <li>
                    3 Technical Implementation
                    <ul>
                        <li>3.1 Technical Challenges</li>
                        <li>3.2 Architecture Overview</li>
                        <li>3.3 Secure Transaction Mechanism: PAFRENs &amp; SACKs</li>
                        <li>3.4 Utilizing Timestamps and Nonces</li>
                        <li>3.5 Managing Balances and Freezes</li>
                        <li>3.6 Smart Contract Deployment and Functionality</li>
                        <li>3.7 Transaction Procedures</li>
                        <li>3.8 Freeze Procedure</li>
                        <li>3.9 Token Transfer</li>
                        <li>3.10 Claiming Earned Tokens</li>
                        <li>3.11 XOneFi SSID Encoder and Hotspot Selection</li>
                    </ul>
                </li>
                <li>
                    4 Tokenomics
                    <ul>
                        <li>4.1 Token Overview</li>
                        <li>4.2 Token Allocation</li>
                        <li>4.3 Utility of the OFI Token</li>
                        <li>4.4 Token Release Schedule</li>
                        <li>4.5 Distribution Model</li>
                        <li>4.6 Governance and Decentralization</li>
                        <li>4.7 Security and Custody</li>
                        <li>4.8 Staking and Yield Generation</li>
                        <li>4.9 Token Burns</li>
                    </ul>
                </li>
                <li>
                    5 Security and Privacy
                    <ul>
                        <li>5.1 Data Protection Measures</li>
                        <li>5.2 Network Security Protocols</li>
                    </ul>
                </li>
                <li>6 Conclusion</li>
            </ul> */}
            <div className="whitepaper-container">
                <div className="sidebar">
                    <h2>Contents</h2>
                    <ul>
                        <li>
                            <a href="#1-introducing-xonefi">1 Introducing XOneFi</a>
                            <ul>
                                <li><a href="#1-1-our-mission">1.1 Our Mission</a></li>
                                <li><a href="#1-2-solving-the-problem-of-internet-inequality">1.2 Solving The Problem of Internet Inequality</a></li>
                                <li><a href="#1-3-xonefi-solution-overview">1.3 XOneFi Solution Overview</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#2-technical-architecture">2 Technical Architecture</a>
                            <ul>
                                <li><a href="#2-1-hardware-components">2.1 Hardware Components</a></li>
                                <li><a href="#2-2-software-components">2.2 Software Components</a></li>
                                <li><a href="#2-3-ethereum-and-layer-2-scaling-solution">2.3 Ethereum and Layer-2 Scaling Solution</a></li>
                                <li><a href="#2-4-wifi-mesh-networking">2.4 WiFi Mesh Networking</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#3-technical-implementation">3 Technical Implementation</a>
                            <ul>
                                <li><a href="#3-1-technical-challenges">3.1 Technical Challenges</a></li>
                                <li><a href="#3-2-architecture-overview">3.2 Architecture Overview</a></li>
                                <li><a href="#3-3-secure-transaction-mechanism-pafrens-sacks">3.3 Secure Transaction Mechanism: PAFRENs & SACKs</a></li>
                                <li><a href="#3-4-utilizing-timestamps-and-nonces">3.4 Utilizing Timestamps and Nonces</a></li>
                                <li><a href="#3-5-managing-balances-and-freezes">3.5 Managing Balances and Freezes</a></li>
                                <li><a href="#3-6-smart-contract-deployment-and-functionality">3.6 Smart Contract Deployment and Functionality</a></li>
                                <li><a href="#3-7-transaction-procedures">3.7 Transaction Procedures</a></li>
                                <li><a href="#3-8-freeze-procedure">3.8 Freeze Procedure</a></li>
                                <li><a href="#3-9-token-transfer">3.9 Token Transfer</a></li>
                                <li><a href="#3-10-claiming-earned-tokens">3.10 Claiming Earned Tokens</a></li>
                                <li><a href="#3-11-xonefi-ssid-encoder-and-hotspot-selection">3.11 XOneFi SSID Encoder and Hotspot Selection</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#4-tokenomics">4 Tokenomics</a>
                            <ul>
                                <li><a href="#4-1-token-overview">4.1 Token Overview</a></li>
                                <li><a href="#4-2-token-allocation">4.2 Token Allocation</a></li>
                                <li><a href="#4-3-utility-of-the-ofi-token">4.3 Utility of the OFI Token</a></li>
                                <li><a href="#4-4-token-release-schedule">4.4 Token Release Schedule</a></li>
                                <li><a href="#4-5-distribution-model">4.5 Distribution Model</a></li>
                                <li><a href="#4-6-governance-and-decentralization">4.6 Governance and Decentralization</a></li>
                                <li><a href="#4-7-security-and-custody">4.7 Security and Custody</a></li>
                                <li><a href="#4-8-staking-and-yield-generation">4.8 Staking and Yield Generation</a></li>
                                <li><a href="#4-9-token-burns">4.9 Token Burns</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#5-security-and-privacy">5 Security and Privacy</a>
                            <ul>
                                <li><a href="#5-1-data-protection-measures">5.1 Data Protection Measures</a></li>
                                <li><a href="#5-2-network-security-protocols">5.2 Network Security Protocols</a></li>
                            </ul>
                        </li>
                        <li><a href="#6-conclusion">6 Conclusion</a></li>
                    </ul>
                </div>
                <div className="content">
                    <h1 id="xonefi-a-tokenized-internet-platform">
                        XOneFi—A Tokenized Internet Platform
                    </h1>
                    <p>Benjamin Yan</p>

                    <h2 id="1-introducing-xonefi">1 Introducing XOneFi</h2>
                    <p>
                        <strong>XOneFi</strong> was founded with the mission of creating a
                        globally-inclusive Internet access network, owned by the people. XOneFi has
                        the potential to significantly boost economic opportunities, provide a
                        scalable and dependable method for universal online access, facilitate
                        global democratic processes, and pave the way for worldwide unified-domain
                        wireless Internet connectivity.
                    </p>
                    <h3 id="1-1-our-mission">1.1 Our Mission</h3>
                    <p>
                        The mission of XOneFi is to create a unified, globally-inclusive Internet
                        access network that seamlessly merges connectivity from cellular, cable, and
                        satellite technologies into one cohesive mesh system. By integrating these
                        different forms of Internet services into a single, decentralized mesh
                        network owned by the people, XOneFi aims to provide affordable,
                        high-quality Internet access to all communities, regardless of location or
                        economic status. Our vision is to bridge the digital divide by making
                        reliable connectivity universally accessible, empowering individuals and
                        fostering social, educational, and economic growth worldwide.
                    </p>
                    <h3 id="1-2-solving-the-problem-of-internet-inequality">
                        1.2 Solving The Problem of Internet Inequality
                    </h3>
                    <p>
                        <strong>Bridging the Digital Divide:</strong> The digital divide is a
                        significant problem worldwide. Many rural, low-income, and sparsely
                        populated areas have little to no access to high-quality Internet services
                        because traditional ISPs lack incentives to cover these regions. XOneFi’s
                        decentralized approach, which enables individuals and small businesses to
                        become community-driven Internet providers, directly addresses this issue.
                        By providing affordable and high-speed Internet access, XOneFi can help
                        bridge the gap between those who have reliable Internet and those who do
                        not.
                    </p>
                    <p>
                        <strong>Economic Opportunities:</strong> By turning individuals and small
                        businesses into mini-ISPs, XOneFi fosters local entrepreneurship and creates
                        new income streams. Communities that previously lacked Internet access could
                        become more economically viable as residents can now participate in the
                        global economy through remote work, online commerce, and other digital
                        activities.
                    </p>
                    <p>
                        <strong>Scalable and Decentralized Solution:</strong> Unlike centralized
                        ISPs, XOneFi relies on a decentralized model that involves community members
                        directly in providing and maintaining Internet access. This model is
                        potentially more resilient and scalable, especially for regions where
                        building traditional infrastructure would be prohibitively expensive. The
                        use of blockchain technology for secure, transparent transactions also helps
                        manage the operational complexity of such a decentralized network.
                    </p>
                    <p>
                        <strong>Facilitating Global Democratic Processes:</strong> Universal
                        Internet access is essential for a healthy democratic process, allowing
                        people to stay informed, share their views, and
                    </p>
                    <p>
                        participate in decision-making. By making Internet access more inclusive and
                        community-driven, XOneFi can contribute to enhancing the global democratic
                        landscape, especially in areas where access to information is limited.
                    </p>
                    <p>
                        <strong>Unified Wireless Connectivity:</strong> XOneFi builds upon WiFi mesh
                        networks, which provide a decentralized and community-driven alternative to
                        traditional cellular networks. Unlike cellular networks, which rely on
                        centralized infrastructure and are often subject to coverage limitations in
                        rural or underserved areas, WiFi mesh networks allow community members to
                        share bandwidth across multiple nodes, providing more consistent coverage
                        and reducing the dependency on ISPs. The WiFi mesh approach also allows for
                        better customization of network parameters based on local needs, resulting
                        in a more flexible and resilient connectivity solution. However, the current
                        fragmentation of WiFi networks, requiring separate subscriptions,
                        credentials, and incompatible domains, makes universal connectivity
                        cumbersome. XOneFi’s concept of a unified WiFi mesh that works across
                        different Internet streams simplifies connectivity, promoting seamless
                        roaming and usage. This kind of cohesive infrastructure could lead to a
                        unified, global wireless domain that makes Internet access as ubiquitous as
                        electricity or water.
                    </p>
                    <p>
                        <strong>Education and Social Growth:</strong> Access to quality Internet is
                        increasingly recognized as a fundamental right that facilitates educational
                        and social development. Students need reliable Internet to study, access
                        learning resources, and connect with educators. By delivering low-cost,
                        high-speed connectivity to underserved areas, XOneFi can help improve
                        educational outcomes and enhance overall quality of life.
                    </p>
                    <h3 id="1-3-xonefi-solution-overview">1.3 XOneFi Solution Overview</h3>
                    <p>
                        XOneFi bridges the digital divide by creating a unified Internet network
                        using decentralized networks, blockchain, and satellite technology to
                        provide affordable, high-quality connectivity for all.
                    </p>
                    <p>
                        <strong>Decentralized Wireless Community Network:</strong> The core of
                        XOneFi’s solution is the Decentralized Wireless Community Network, a
                        community-driven model that empowers individuals and small businesses to
                        provide Internet access in their areas. By enabling people to become
                        mini-ISPs (Internet Service Providers), XOneFi fosters local ownership and
                        incentivizes community involvement. This approach reduces dependency on
                        traditional ISPs, which often neglect low-income and sparsely populated
                        areas. The decentralized model also ensures that the network is resilient,
                        flexible, and adaptable to the specific needs of each community, creating a
                        fairer distribution of Internet access worldwide.
                    </p>
                    <p>
                        <strong>Tokenizing Internet Connectivity:</strong> XOneFi leverages
                        blockchain technology to tokenize Internet connectivity, making it
                        feasible for individuals to share their Internet connections and be rewarded
                        for their contributions. By creating a blockchain-based ecosystem, XOneFi
                        provides a transparent, secure, and equitable way to manage payments and
                        resource allocation. The introduction of a utility token incentivizes
                        participation and allows for seamless transactions without traditional
                        banking infrastructure. By tokenizing Internet sharing, we empower people to
                        monetize their Internet resources, leading to more equitable access across
                        different communities.
                    </p>
                    <p>
                        <strong>Leveraging LEO Satellites:</strong> To expand connectivity and reach
                        underserved areas, XOneFi utilizes Low Earth Orbit (LEO) satellites such as
                        SpaceX Starlink, OneWeb, and Amazon Kuiper. These satellites provide
                        widespread coverage, especially in remote or geographically challenging
                        areas where traditional infrastructure is impractical or too expensive. By
                        repurposing LEO satellite Internet links, XOneFi enables the creation of
                        shared, high-speed connections that are managed by local mini-ISPs. This
                        combination of satellite technology and community-driven deployment ensures
                        that even the most isolated regions can access reliable Internet
                        connectivity, closing the gap between urban and rural areas.
                    </p>
                    <h2 id="2-technical-architecture">2 Technical Architecture</h2>
                    <p>
                        Figure 1 offers an overview of XOneFi system architecture. XOneFi’s
                        technical architecture consists of multiple interconnected components,
                        providing a decentralized, secure, and scalable Internet-sharing platform.
                        This chapter covers the four main sections of XOneFi’s architecture:
                        hardware components, software components, Ethereum and Layer-2 scaling
                        solutions, and WiFi mesh networking.
                    </p>
                    <pre>
                        <code>
                            <span className="hljs-attribute">Figure 1</span>: XOneFi architecture
                            {"\n"}
                        </code>
                    </pre>
                    <h3 id="2-1-hardware-components">2.1 Hardware Components</h3>
                    <p>
                        XOneFi relies on specialized hardware components to deliver eﬀicient,
                        decentralized wireless networks to underserved communities. The key
                        hardware component is the provider router equipped with customized firmware
                        to seamlessly integrate with the XOneFi network.
                    </p>
                    <p>
                        <strong>Routers:</strong> XOneFi uses high-performance routers designed to
                        handle multiple connections simultaneously, supporting a variety of user
                        demands. These routers are pre-configured with XOneFi’s customized firmware,
                        which allows them to work as mini-ISPs within the XOneFi network. The
                        routers are capable of managing bandwidth allocation, ensuring optimal
                        performance, and enabling seamless communication between devices. One unique
                        aspect of XOneFi is that it allows users to{" "}
                        <strong>use their own routers</strong> and configure them into XOneFi
                        routers by downloading and installing the XOneFi firmware.
                    </p>
                    <p>
                        <strong>Customized Firmware:</strong> The firmware running on XOneFi routers
                        is customized to support XOneFi’s unique network requirements. It
                        incorporates modules such as the Connection Manager, Dynamic Client
                        Roster, and Per-Client Firewall Policy. The firmware is responsible for
                        managing connections between clients and the provider’s router, tracking
                        the list of clients connected at any given time, and enforcing security
                        policies for each client. The Connection Manager handles the allocation of
                        available bandwidth and ensures clients are connected based on their payment
                        status and service quality requirements. The Dynamic Client Roster
                        maintains up-to-date information about clients, while the Per-Client
                        Firewall Policy enforces security policies for individual connections,
                        ensuring network security.
                    </p>
                    <h3 id="2-2-software-components">2.2 Software Components</h3>
                    <p>
                        XOneFi’s software components play a crucial role in managing connectivity,
                        payments, and overall network functionality. These components are
                        distributed across user-facing applications, backend systems, and blockchain
                        integration.
                    </p>
                    <p>
                        <strong>XOneFi iOS and Android Apps:</strong> XOneFi offers mobile
                        applications for both iOS and Android platforms, providing an easy and
                        intuitive way for users to connect to the XOneFi network. These apps enable
                        users to locate available hotspots, manage payments using OFI tokens, and
                        monitor data usage. The apps are designed to offer a seamless experience,
                        allowing users to manage their connectivity without any technical expertise.
                        The mobile apps also facilitate the interaction between users and providers,
                        including PAFREN and SACK exchanges to ensure the availability of funds and
                        service delivery.
                    </p>
                    <p>
                        <strong>XOneFi Web Application:</strong> The XOneFi Web App provides
                        additional functionality for both users and providers, which could run on
                        desktops and laptops. It allows users to manage their accounts, track data
                        usage, and access additional services. For providers, the web app offers
                        tools for managing their hotspots, setting up service plans, and monitoring
                        network performance. The web app also acts as a centralized interface for
                        onboarding new community providers, ensuring easy integration into the
                        XOneFi network.
                    </p>
                    <p>
                        <strong>Backend Server Software:</strong> The XOneFi backend server is
                        responsible for managing network-wide operations, authentication, and
                        communication between various nodes. It includes components such as the
                        Update Pinger and the REST Endpoint, which handle network updates and
                        facilitate communication between clients and providers. The back-end server
                        also plays a key role in managing security by verifying client credentials
                        and executing smart contract transactions on the blockchain. Additionally,
                        the backend system interfaces with the blockchain to ensure all
                        transactions are processed securely and eﬀiciently.
                    </p>
                    <h3 id="2-3-ethereum-and-layer-2-scaling-solution">
                        2.3 Ethereum and Layer-2 Scaling Solution
                    </h3>
                    <p>
                        To enable secure and eﬀicient transactions within the XOneFi network, the
                        OFI token is built on the Ethereum blockchain, with additional integration
                        of Layer-2 scaling solutions to optimize performance and reduce transaction
                        fees.
                    </p>
                    <p>
                        <strong>Ethereum Blockchain:</strong> The OFI token is an ERC-20 token
                        deployed on the Ethereum blockchain, which provides a secure and
                        decentralized platform for managing payments and transactions. Ethereum’s
                        established ecosystem allows for the integration of smart contracts, which
                        are used to automate payment settlements between clients and providers.
                        These contracts manage the freezing of funds using PAFREN tokens and allow
                        providers to claim funds through SACK tokens once the service is delivered.
                    </p>
                    <p>
                        <strong>Layer-2 Scaling Solution:</strong> To ensure scalability and
                        cost-eﬀiciency, XOneFi uses a Layer-2 solution for processing transactions.
                        By leveraging Layer-2 Roll-ups, XOneFi is able to process a high volume of
                        micro-transactions between users and providers at a fraction of the cost
                        compared to the main Ethereum network. This solution not only reduces gas
                        fees but also increases the transaction throughput, making it feasible for
                        small-scale transactions within the community-driven network. The
                        integration of Layer-2 scaling is essential to keep transaction costs low,
                        particularly for users in low-income regions.
                    </p>
                    <p>
                        <strong>Smart Contract Functionality:</strong> Smart contracts in the XOneFi
                        network handle key functions, such as freezing client funds (using PAFREN
                        tokens), releasing funds (via SACK tokens), and managing per-client
                        transactions. The contracts are designed with security in mind, including
                        mechanisms to prevent double-spending and ensure that only authorized users
                        can initiate transactions. By automating these financial transactions, smart
                        contracts reduce the risk of fraud and ensure transparency throughout the
                        network.
                    </p>
                    <h3 id="2-4-wifi-mesh-networking">2.4 WiFi Mesh Networking</h3>
                    <p>
                        The <strong>WiFi Mesh Networking</strong> component of XOneFi plays a vital
                        role in delivering stable and reliable Internet connectivity across
                        different environments. XOneFi utilizes a mesh network topology to ensure
                        that all community members have access to consistent high-quality Internet
                        service.
                    </p>
                    <p>
                        <strong>Universal WiFi Mesh:</strong> The routers deployed by XOneFi form a{" "}
                        <strong>universal WiFi mesh</strong>
                        network that supports seamless connectivity for users moving across
                        different locations. This mesh design allows for multiple routers to work
                        together, providing redundancy and eliminating dead zones within the
                        coverage area. The mesh network ensures that users can maintain a stable
                        connection without needing to reconnect when moving between hotspots.
                    </p>
                    <p>
                        <strong>Interoperability Between Hotspots:</strong> One of the key features
                        of XOneFi’s mesh network is its interoperability between different
                        providers’ hotspots. This is facilitated through the shared use of{" "}
                        <strong>LEO satellite links</strong> and the standardized XOneFi firmware,
                        which ensures that hotspots can serve each other’s clients. This
                        interoperability not only
                    </p>
                    <p>
                        increases the coverage area but also improves the user experience by
                        providing consistent service across different regions and providers.
                    </p>
                    <p>
                        <strong>Bandwidth Optimization:</strong> The WiFi mesh network also
                        incorporates mechanisms for bandwidth optimization. The firmware includes
                        algorithms to allocate bandwidth dynamically based on user demand and
                        payment status, ensuring that clients who contribute more to the network
                        receive higher service quality. This dynamic allocation helps in eﬀi-
                        ciently utilizing the available satellite bandwidth and maintaining a high
                        level of service for all users.
                    </p>
                    <p>
                        <strong>Security and Privacy:</strong> Each router within the WiFi mesh
                        network enforces <strong>Per-Client Firewall Policies</strong> , which are
                        tailored to ensure data security and privacy for all users. By isolating
                        client traﬀic and preventing unauthorized access, XOneFi maintains a high
                        level of security throughout the network. The use of{" "}
                        <em>end-to-end encryption</em> for communication between clients and the
                        backend server further enhances privacy, ensuring that users’ data remains
                        protected at all times.
                    </p>
                    <h2 id="3-technical-implementation">3 Technical Implementation</h2>
                    <h3 id="3-1-technical-challenges">3.1 Technical Challenges</h3>
                    <p>XOneFi addresses three fundamental technical implementation challenges:</p>
                    <ul>
                        <li>
                            Voided Check Problem: Ensuring that funds are available at the time of
                            transaction settlement when clients provide payment credentials.
                        </li>
                        <li>
                            Overdraft Problem: Preventing overdrafts when payment credentials are
                            shared among multiple providers, ensuring the suﬀiciency of the final
                            balance.
                        </li>
                        <li>
                            Billing Aggregation Problem: Avoiding high transaction fees while
                            aggregating multiple payments into a single transaction.
                        </li>
                    </ul>
                    <h3 id="3-2-architecture-overview">3.2 Architecture Overview</h3>
                    <p>
                        To realize the vision of providing decentralized, equitable Internet access,
                        XOneFi employs a sophisticated technical architecture that integrates
                        blockchain technology, cryptographic techniques, and communication
                        protocols. This section delves into the core components of XOneFi’s
                        technical implementation.
                    </p>
                    <h3 id="3-3-secure-transaction-mechanism-pafrens-sacks">
                        3.3 Secure Transaction Mechanism: PAFRENs &amp; SACKs
                    </h3>
                    <p>
                        XOneFi introduces a unique cryptographic protocol to manage transactions
                        between clients and hotspots, ensuring secure and fair exchange of services
                        and payments. The
                    </p>
                    <p>
                        protocol is built around two key cryptographic tokens:{" "}
                        <strong>PAFREN (Partial Freeze Endorsement)</strong> and{" "}
                        <strong>SACK (Satisfaction Acknowledgement)</strong>. Together, these tokens
                        provide a structured mechanism to guarantee that clients receive the
                        services they pay for while ensuring that hotspots are compensated fairly.
                    </p>
                    <p>
                        The PAFREN token allows hotspots to temporarily freeze a portion of a
                        client’s funds as a guarantee for payment. When a client connects to a
                        hotspot, they issue a PAFREN to the hotspot, freezing a specified amount of
                        their OFI tokens until a predetermined deadline. This mechanism ensures that
                        funds are reserved to pay for the services provided. The frozen funds cannot
                        be transferred, sold, or frozen by another party until the deadline passes.
                        This protects both the client and the hotspot, creating a fair exchange
                        process.
                    </p>
                    <p>
                        On the other hand, the SACK token enables hotspots to claim payment for
                        services rendered. Once a client receives satisfactory service—measured by
                        bandwidth, throughput, and other quality metrics—they send a SACK to the
                        hotspot. This token acts as a confirmation that the service was delivered as
                        expected, allowing the hotspot to claim the frozen funds. Importantly, each
                        freeze allows the hotspot to claim funds only once, ensuring accurate
                        billing and preventing overcharging.
                    </p>
                    <p>
                        This dual-token transaction mechanism—comprising PAFRENs and
                        SACKs—establishes a secure, trust-based relationship between clients and
                        hotspots. Clients are assured that their funds will only be released if the
                        service meets their expectations, while hotspots are guaranteed payment for
                        the services they provide. This structure helps maintain fairness and
                        transparency within the XOneFi network, and fosters trust and encourages
                        broader participation in the decentralized Internet access solution.
                    </p>
                    <h3 id="3-4-utilizing-timestamps-and-nonces">
                        3.4 Utilizing Timestamps and Nonces
                    </h3>
                    <p>
                        To enhance security and eﬀiciency, XOneFi leverages timestamps both as
                        deadlines and as nonces in cryptographic operations. This approach is
                        integral to reducing transaction fees and preventing replay attacks,
                        ensuring that transactions are secure and streamlined for both clients and
                        hotspots.
                    </p>
                    <p>
                        In XOneFi, current timestamps are used as nonces. By using only current
                        timestamps and not future ones, the system effectively prevents the reuse of
                        nonces. This helps mitigate the risk of replay attacks, where an attacker
                        could potentially reuse a transaction to exploit the system. The use of
                        timestamps as nonces eliminates the need for additional data fields, thereby
                        reducing the computational and storage requirements and, ultimately, the
                        transaction fees.
                    </p>
                    <p>
                        The system also incorporates a nonce mapping mechanism to track used
                        timestamps. This ensures that each transaction is unique and that previously
                        used nonces cannot be reused. Even in the event of reasonable clock
                        asynchronization between the hotspot and the client, the use of current
                        timestamps as nonces remains effective and secure. By storing used
                        timestamps, XOneFi ensures that repeated values are not possible within a
                        properly functioning protocol, which further strengthens the security of the
                        network.
                    </p>
                    <p>
                        Overall, the use of timestamps and nonces in XOneFi serves a dual purpose:
                        reducing transaction costs and enhancing the security of transactions.
                        This approach ensures
                    </p>
                    <p>
                        that the network remains eﬀicient while providing robust protection against
                        common blockchain security threats, ultimately contributing to a secure and
                        cost-effective decentralized Internet access solution.
                    </p>
                    <h3 id="3-5-managing-balances-and-freezes">
                        3.5 Managing Balances and Freezes
                    </h3>
                    <p>
                        XOneFi’s smart contract keeps track of two balances for each user: the
                        actual balance and the available balance. The actual balance represents the
                        total number of OFI tokens held by the user, while the available balance
                        refers to the portion of tokens not currently frozen and thus available for
                        immediate use. To ensure that funds are securely managed while also keeping
                        the transaction fees low, XOneFi employs a unique mechanism for managing
                        frozen funds and ensuring their availability when appropriate.
                    </p>
                    <p>
                        One of the key components in managing balances is the CHEDGACO (Cheap
                        Expired Deadlines Garbage Collection) algorithm. The CHEDGACO algorithm
                        addresses the challenge of updating expired freezes without incurring high
                        transaction fees. In traditional systems, iterating over multiple expired
                        freezes to update balances can be computationally expensive. CHEDGACO
                        solves this problem by keeping a mapping of the last freeze deadline for
                        each account, which helps streamline the process of updating balances.
                    </p>
                    <p>
                        When a freeze expires, the algorithm checks if the current timestamp exceeds
                        the stored deadline. If the current timestamp is larger, it means that there
                        are no active freezes, and thus, the entire balance is considered available
                        without needing to iterate over each expired freeze individually. This
                        approach significantly reduces computational overhead, making the process of
                        balance updates more eﬀicient and cost-effective.
                    </p>
                    <p>
                        The CHEDGACO algorithm leverages the idea that the build-up of freezes
                        typically applies to clients in motion, such as those moving between
                        hotspots. However, when a client stops moving, the algorithm can take
                        advantage of the last deadline mapping to bulkpurge expired sessions. This
                        ensures that the available balance is kept up to date without the need for
                        frequent, costly iterations over multiple deadlines. After receiving the
                        last SACK, the hotspot owner is required to promptly exchange it into OFI
                        tokens before the reserved funds become unfrozen. The contract includes a
                        constant that specifies the number of seconds after the PAFREN expiration
                        during which the funds remain frozen, allowing the hotspot suﬀicient time to
                        claim them.
                    </p>
                    <p>
                        By using CHEDGACO, XOneFi ensures that balance management remains eﬀicient
                        and that users’ available balances are accurately reflected without
                        unnecessary computational costs. This approach helps maintain a seamless
                        experience for both clients and hotspots while preserving the security and
                        integrity of the network.
                    </p>
                    <h3 id="3-6-smart-contract-deployment-and-functionality">
                        3.6 Smart Contract Deployment and Functionality
                    </h3>
                    <p>
                        The XOneFi smart contract extends the ERC-20 standard with specialized
                        functions to support the network’s requirements.
                    </p>
                    <p>
                        The smart contract provides two main token exchange functions: OFI to Ether
                        conversion and SACK redemption. Users can convert their OFI tokens to Ether
                        directly within the
                    </p>
                    <p>
                        contract, enabling a seamless transition between cryptocurrencies. In
                        addition, hotspots can redeem SACKs to claim the OFI tokens frozen by
                        clients. This ensures that the service providers are compensated for the
                        services they offer, while also maintaining transparency and fairness.
                    </p>
                    <p>
                        The interaction between clients and hotspots is straightforward. Clients
                        issue PAFRENs to hotspots upon connection, which freezes the specified
                        amount of tokens. Once the service is satisfactorily delivered, hotspots
                        receive SACKs, which they can then redeem through the smart contract. The
                        contract facilitates the freezing and unfreezing of funds in a secure
                        manner, and ensures fair transactions between all parties.
                    </p>
                    <h3 id="3-7-transaction-procedures">3.7 Transaction Procedures</h3>
                    <p>
                        The transaction procedures in XOneFi involve several mechanisms to ensure
                        the proper freezing and claiming of funds, facilitating smooth interactions
                        between clients and hotspots. These processes are crucial for guaranteeing
                        that services are delivered and paid for fairly.
                    </p>
                    <h3 id="3-8-freeze-procedure">3.8 Freeze Procedure</h3>
                    <p>
                        When a client connects to a hotspot, the contract first verifies that the
                        client’s available balance is suﬀicient for the requested freeze amount.
                        Once the balance is confirmed, the PAFREN provided by the client is
                        validated to ensure that it is unique—meaning the nonce has not been
                        reused—and that it is properly signed by the client. After successful
                        validation, the specified amount of funds is frozen, and the freeze deadline
                        is recorded in the contract. Finally, the available balance of the client is
                        updated by reducing it by the frozen amount, ensuring that only unfrozen
                        funds remain accessible for other purposes.
                    </p>
                    <h3 id="3-9-token-transfer">3.9 Token Transfer</h3>
                    <p>
                        To account for the possibility of frozen funds, the standard ERC-20 token
                        transfer function has been modified. Transfers are only allowed if the
                        sender’s available balance—i.e., the portion of funds not currently
                        frozen—covers the transfer amount. If the available balance is suﬀicient,
                        the transfer proceeds, and the contract updates the available balances of
                        both the sender and the recipient accordingly. This ensures that the
                        transfer adheres to the system’s rules and maintains a transparent
                        transaction history for all participants.
                    </p>
                    <h3 id="3-10-claiming-earned-tokens">3.10 Claiming Earned Tokens</h3>
                    <p>
                        When a hotspot seeks to claim payment for services rendered, it does so by
                        submitting a SACK. The SACK is verified for authenticity and uniqueness to
                        ensure that it has not been previously used. Once validated, the
                        corresponding amount of tokens is transferred from the client’s frozen
                        balance to the hotspot. Upon completion of the claim, the freeze is lifted,
                        and the client’s frozen balance is updated. The available balance of the
                        hotspot
                    </p>
                    <p>
                        provider’s account is further updated to reflect the received tokens. This
                        procedure guarantees that hotspots are compensated for their services
                        while maintaining a fair and secure transaction environment.
                    </p>
                    <p>
                        These procedures ensure that the balance management system in XOneFi remains
                        secure, transparent, and eﬀicient, providing a fair mechanism for all
                        parties involved in transactions on the network.
                    </p>
                    <p>
                        <strong>Security Enhancements</strong>
                    </p>
                    <p>
                        To ensure robust security throughout the transaction process, XOneFi has
                        implemented several key measures. These enhancements are designed to protect
                        the integrity of transactions, prevent misuse, and provide a secure
                        environment for both clients and hotspots to operate within the network.
                    </p>
                    <p>
                        One of the primary security measures is the use of prefix separation for
                        different types of cryptographic tokens. PAFRENs and SACKs each include
                        distinct prefixes—‘P’ for PAFREN and ‘S’ for SACK—to prevent misuse or
                        confusion between the two. This prevents malicious actors from attempting to
                        use a PAFREN in place of a SACK or vice versa, as each token type has a
                        unique identifier that differentiates its purpose within the system. This
                        measure adds an extra layer of security to ensure that funds are only
                        accessed as intended.
                    </p>
                    <p>
                        Another important security feature is the prevention of replay attacks. In
                        XOneFi, current timestamps are used as nonces, and their usage is tracked to
                        ensure that each transaction is unique. By preventing the reuse of nonces,
                        the system effectively mitigates the risk of replay attacks, where an
                        attacker could potentially resend a valid data transmission to exploit the
                        network. This approach ensures that once a transaction has been completed,
                        it cannot be duplicated, thereby safeguarding user funds and maintaining the
                        integrity of the transaction process.
                    </p>
                    <p>
                        Additionally, XOneFi incorporates a grace period following the expiration of
                        a PAFREN. After the PAFREN expires, there is a predefined period during
                        which the funds remain frozen, allowing hotspots suﬀicient time to claim the
                        funds. This grace period helps protect the interests of the hotspots,
                        ensuring they have ample opportunity to receive compensation for the
                        services they provided, even if a client disconnects unexpectedly or if
                        there are minor delays in the transaction process.
                    </p>
                    <p>
                        Together, these security enhancements ensure that the XOneFi network remains
                        secure, transparent, and fair for all participants. By combining prefix
                        separation, nonce tracking, and a grace period for fund claims, XOneFi
                        creates a secure environment that fosters trust among users and service
                        providers, ultimately contributing to the stability and reliability of the
                        decentralized Internet access solution.
                    </p>
                    <h3 id="3-11-xonefi-ssid-encoder-and-hotspot-selection">
                        3.11 XOneFi SSID Encoder and Hotspot Selection
                    </h3>
                    <p>
                        To optimize network connectivity and enhance the user experience, XOneFi
                        employs an innovative approach to hotspot identification and selection. This
                        approach focuses on
                    </p>
                    <p>
                        ensuring that clients are seamlessly connected to the most suitable hotspot
                        available, based on multiple factors that influence performance and service
                        quality.
                    </p>
                    <p>
                        The SSID encoding system is a key part of this process. Each XOneFi hotspot
                        embeds metadata within its SSID, which provides crucial information about
                        the hotspot’s capabilities, such as bandwidth availability, performance
                        metrics, and service terms. This embedded data allows clients to make
                        informed decisions about which hotspot to connect to, ensuring that their
                        connectivity needs are met eﬀiciently. By including performance-related
                        metadata, XOneFi ensures that users can easily identify and connect to
                        hotspots that offer the best service quality. The SSID encoder is shown in
                        the Figure 2.
                    </p>
                    <pre>
                        <code>
                            <span className="hljs-attribute">Figure 2</span>: XOneFi encoder{"\n"}
                        </code>
                    </pre>
                    <p>
                        In addition to the SSID encoding system, XOneFi uses a Fast Hotspot
                        Selection (FHS) algorithm to further optimize the selection process. The FHS
                        algorithm is designed to eﬀiciently identify the best available hotspot
                        based on multiple criteria without requiring extensive comparisons. This
                        algorithm utilizes non-comparative multi-criteria radix sorting to rank
                        hotspots, which significantly reduces the computational complexity of the
                        selection process. By streamlining the selection process, the FHS algorithm
                        ensures that clients can quickly connect to the most suitable hotspot,
                        minimizing delays and interruptions in service.
                    </p>
                    <p>
                        Moreover, XOneFi incorporates anonymized session data stored on a
                        decentralized ledger to inform the hotspot selection process. This
                        historical performance data helps the system make more accurate selections
                        by considering each hotspot’s past performance and reliability. By
                        leveraging this data, XOneFi can provide users with a consistently
                        high-quality
                    </p>
                    <p>
                        connection experience, as the system is able to prioritize hotspots that
                        have demonstrated strong performance over time.
                    </p>
                    <p>
                        The combination of SSID encoding, the FHS algorithm, and the use of
                        historical performance data provides significant benefits to users.
                        Clients are automatically connected to the most suitable hotspot, which
                        optimizes network eﬀiciency and ensures a high-quality user experience. By
                        reducing connection delays and improving overall service quality, XOneFi
                        delivers a reliable and seamless Internet access solution that meets the
                        needs of diverse communities around the world.
                    </p>
                    <h2 id="4-tokenomics">4 Tokenomics</h2>
                    <h3 id="4-1-token-overview">4.1 Token Overview</h3>
                    <p>
                        The OFI token is the native cryptocurrency of the XOneFi network. It is an
                        ERC-token deployed on the Ethereum Mainnet, with additional Layer 2
                        solutions integrated for faster transactions and reduced gas fees. The OFI
                        token is designed to incentivize participation, ensure network
                        sustainability, and facilitate community governance.
                    </p>
                    <ul>
                        <li>
                            <strong>Initial Supply:</strong> 10 billion OFI tokens.
                        </li>
                        <li>
                            <strong>Circulating Supply:</strong> The tokens will be gradually released
                            over time to align with network growth and adoption.
                        </li>
                        <li>
                            <strong>Max Supply:</strong> Fixed at 10 billion tokens to ensure scarcity
                            and promote value appreciation.
                        </li>
                    </ul>
                    <h3 id="4-2-token-allocation">4.2 Token Allocation</h3>
                    <p>
                        To foster a balanced ecosystem, the OFI token allocation is structured as
                        follows:
                    </p>
                    <ul>
                        <li>
                            <strong>Community Rewards (70%):</strong> 7 billion OFI tokens will be
                            distributed as rewards to users who contribute to the network. This
                            includes individuals who share their Internet connectivity, act as
                            mini-ISPs, or participate in other activities that enhance network
                            coverage and reliability. These rewards are crucial for driving adoption
                            and building a resilient community driven network.
                        </li>
                        <li>
                            <strong>Development Team (10%):</strong> 1 billion tokens are allocated to
                            the development team to support ongoing platform improvements,
                            maintenance, and infrastructure upgrades. This allocation ensures that the
                            technical foundation of XOneFi remains strong and that the network can
                            evolve in response to community needs.
                        </li>
                        <li>
                            <strong>Investors (15%):</strong> 1.5 billion tokens will be allocated to
                            early investors who provided the initial funding necessary to launch and
                            scale the XOneFi project. This allocation is intended to attract strategic
                            partners and secure the resources needed for network expansion.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <strong>Ecosystem Fund (5%)</strong> : 500 million tokens will be reserved
                            for partnerships, grants, research, and initiatives that contribute to the
                            growth and decentralization of the XOneFi ecosystem. The ecosystem fund
                            will support collaborations with other projects, educational programs, and
                            the onboarding of underserved communities.
                        </li>
                    </ul>
                    <h3 id="4-3-utility-of-the-ofi-token">4.3 Utility of the OFI Token</h3>
                    <p>
                        The OFI token is central to the functioning of the XOneFi network, serving
                        multiple roles within the ecosystem:
                    </p>
                    <ul>
                        <li>
                            <strong>Payment and Reward System:</strong> OFI tokens are used to reward
                            users who share their Internet connections and provide decentralized
                            services. This incentivizes participation by offering a means for
                            community members to earn passive income, contributing to the network’s
                            growth.
                        </li>
                        <li>
                            <strong>Governance:</strong> OFI token holders have voting rights in the
                            governance of the XOneFi network. Token holders can propose and vote on
                            key decisions, including changes to the protocol, distribution of funds,
                            and community initiatives. This ensures that the network evolves based on
                            collective decision-making.
                        </li>
                        <li>
                            <strong>Access to Services:</strong> The OFI token is also used to access
                            premium features and services within the XOneFi ecosystem, such as
                            enhanced connectivity options, priority bandwidth, and exclusive
                            community programs.
                        </li>
                    </ul>
                    <h3 id="4-4-token-release-schedule">4.4 Token Release Schedule</h3>
                    <p>
                        The release schedule for OFI tokens is designed to balance network growth
                        with sustainable value:
                    </p>
                    <ul>
                        <li>
                            <strong>Initial Launch:</strong> 500 million tokens will be made available
                            at launch to incentivize early adopters and network participants.
                        </li>
                        <li>
                            <strong>Unlock Schedule:</strong> The remaining tokens will be gradually
                            unlocked over a 15-year period. This slow release ensures that the supply
                            aligns with the growth of the network, preventing inflationary pressures
                            and maintaining token value.
                        </li>
                        <li>
                            <strong>Inflation:</strong> For the first 15 years, there will be no
                            inflation. Afterward, the community may decide on an annual inflation
                            rate of up to 1.5% to sustain rewards for participants and cover
                            operational costs. This inflation will be governed through a decentralized
                            voting process, ensuring transparency and community consensus.
                        </li>
                    </ul>
                    <h3 id="4-5-distribution-model">4.5 Distribution Model</h3>
                    <p>
                        The distribution of OFI tokens is centered on promoting network
                        participation and long-term sustainability:
                    </p>
                    <ul>
                        <li>
                            <strong>Community Incentives:</strong> Users who share their connectivity
                            through XOneFi will receive OFI tokens as rewards. This model incentivizes
                            users to expand the network’s reach, especially in underserved regions.
                            Rewards are based on metrics such as bandwidth shared, service
                            reliability, and the number of users served.
                        </li>
                        <li>
                            <strong>Ecosystem Development:</strong> A portion of the tokens will be
                            used to fund research and development projects, infrastructure
                            improvements, partnerships, and initiatives that promote network
                            decentralization. Grants will be offered to developers, researchers, and
                            organizations that contribute to the network’s growth and technological
                            advancements.
                        </li>
                    </ul>
                    <h3 id="4-6-governance-and-decentralization">
                        4.6 Governance and Decentralization
                    </h3>
                    <p>
                        XOneFi is committed to progressively decentralizing control over the
                        network, empowering the community to shape its future:
                    </p>
                    <ul>
                        <li>
                            <strong>Governance Mechanism:</strong> Initially, governance will operate
                            on a “one token, one vote” basis. As the network grows, XOneFi aims to
                            implement a “one person, one vote” governance model to better align with
                            its community centric values and reduce the influence of large token
                            holders.
                        </li>
                        <li>
                            <strong>Community Proposals:</strong> Any OFI token holder can submit
                            proposals for network upgrades, token allocation changes, or community
                            initiatives. Voting on these proposals will be conducted transparently,
                            with results recorded on the blockchain to ensure accountability.
                        </li>
                        <li>
                            <strong>Decentralization Goals:</strong> Over time, XOneFi intends to
                            transfer key decision making powers to the community, ensuring that the
                            network remains open, transparent, and community driven. The progressive
                            decentralization plan includes the creation of autonomous community
                            councils and partnerships with other decentralized projects.
                        </li>
                    </ul>
                    <h3 id="4-7-security-and-custody">4.7 Security and Custody</h3>
                    <p>
                        To ensure the safety of the OFI token and maintain trust within the
                        community, XOneFi has implemented robust security measures:
                    </p>
                    <ul>
                        <li>
                            <strong>Token Custody:</strong> The majority of OFI tokens will be stored
                            in cold wallets, which are not connected to the Internet, thus minimizing
                            the risk of hacks. Operational tokens required for day to day transactions
                            will be managed in hot wallets with multi-signature security to ensure
                            safety and accessibility.
                        </li>
                        <li>
                            <strong>Audits and Security Measures:</strong> The OFI smart contract,
                            along with all associated systems, will undergo regular audits by
                            reputable external security firms. These audits will identify
                            vulnerabilities and provide recommendations to enhance the security of
                            the network. Additionally, a bug bounty program will be implemented to
                            incentivize independent security researchers to identify and report
                            potential issues.
                        </li>
                    </ul>
                    <h3 id="4-8-staking-and-yield-generation">
                        4.8 Staking and Yield Generation
                    </h3>
                    <p>
                        XOneFi also provides staking opportunities for OFI token holders to further
                        incentivize participation:
                    </p>
                    <ul>
                        <li>
                            <strong>Staking Mechanism:</strong> OFI holders can stake their tokens to
                            support network operations, such as transaction validation and providing
                            liquidity. In return, stakers will receive a portion of the transaction
                            fees and newly minted tokens as rewards.
                        </li>
                        <li>
                            <strong>Yield Farming:</strong> To boost liquidity in decentralized
                            exchanges, yield farming opportunities will be offered. Users can
                            provide liquidity to OFI trading pairs and earn rewards in OFI tokens,
                            fostering liquidity and improving the eﬀiciency of the token market.
                        </li>
                    </ul>
                    <h3 id="4-9-token-burns">4.9 Token Burns</h3>
                    <p>
                        To manage supply and maintain value, XOneFi will implement token burning
                        mechanisms:
                    </p>
                    <ul>
                        <li>
                            <strong>Burning Mechanism:</strong> A portion of the transaction fees
                            collected within the network will be used to buy back and burn OFI
                            tokens. This will reduce the circulating supply over time, helping to
                            maintain scarcity and support the token’s value.
                        </li>
                        <li>
                            <strong>Community Driven Burns:</strong> Token holders can vote on the
                            amount of OFI tokens to be burned from the ecosystem fund, allowing the
                            community to play an active role in managing token supply and ensuring the
                            sustainability of the network.
                        </li>
                    </ul>
                    <p>
                        By focusing on community rewards, decentralized governance, and robust
                        security, the OFI token will play a crucial role in bridging the digital
                        divide and empowering underserved communities with reliable Internet
                        access.
                    </p>
                    <h2 id="5-security-and-privacy">5 Security and Privacy</h2>
                    <p>
                        XOneFi places the utmost importance on security and privacy, ensuring that
                        both user data and the network itself are protected against potential
                        threats. This section outlines XOneFi’s approach to safeguarding user data
                        and the protocols implemented to maintain a secure network environment. The
                        focus is on two main areas: data protection measures and network security
                        protocols.
                    </p>
                    <h3 id="5-1-data-protection-measures">5.1 Data Protection Measures</h3>
                    <p>
                        Ensuring the security of user data is a critical priority for XOneFi. The
                        following measures are implemented to protect user data throughout the
                        network.
                    </p>
                    <p>
                        <strong>End-to-End Encryption:</strong> To guarantee the confidentiality of
                        user communications, XOneFi employs end to end encryption (E2EE) for all
                        data transmitted across the network. This ensures that data is encrypted
                        on the user’s device and only decrypted by the intended recipient, making it
                        impossible for any intermediary or third party to access the information.
                        The use of E2EE helps in protecting sensitive information such as payment
                        details, authentication credentials, and browsing activity from being
                        intercepted or compromised.
                    </p>
                    <p>
                        <strong>XOneFi VPN Integration:</strong> To further enhance user privacy,
                        XOneFi offers VPN integration as an additional layer of security. By
                        allowing users to route their Internet traﬀic through a Virtual Private
                        Network (VPN), XOneFi ensures that user data remains anonymous and protected
                        from potential surveillance or interception. This integration helps in
                        masking users’ IP addresses and encrypting their online activities, adding
                        an extra level of privacy beyond standard encryption protocols.
                    </p>
                    <p>
                        <strong>Data Anonymization:</strong> XOneFi implements data anonymization to
                        protect user privacy. User data that is collected for network analytics or
                        performance optimization is anonymized to ensure that no personally
                        identifiable information (PII) can be linked back to specific individuals.
                        This anonymization is done using techniques such as data masking,
                        tokenization, and generalization. By anonymizing user data, XOneFi ensures
                        compliance with data privacy regulations and reduces the risk of identity
                        theft.
                    </p>
                    <p>
                        <strong>Local Data Storage and Limited Retention:</strong> Sensitive user
                        data is stored locally on user devices rather than centralized servers.
                        XOneFi follows the principle of data minimization, storing only the
                        information that is essential for the operation of the network. When data is
                        stored on XOneFi servers, strict access control policies are enforced to en
                        sure only authorized personnel can access it. Moreover, data retention is
                        limited data is deleted automatically after its purpose is fulfilled,
                        minimizing the risk of data breaches and misuse.
                    </p>
                    <p>
                        <strong>Blockchain Based Security:</strong> The use of the Ethereum
                        blockchain provides an added layer of data integrity and security. All
                        payment related information and transaction records are stored immutably on
                        the blockchain, making it tamperproof and resistant to data manipulation.
                        The use of smart contracts to manage transactions ensures that payment and
                        fund management processes are both transparent and secure, with no risk of
                        data being altered once recorded on the blockchain.
                    </p>
                    <p>
                        <strong>User Authentication and Identity Management:</strong> XOneFi uses
                        multi-factor authentication (MFA) to protect user accounts from
                        unauthorized access. In addition to passwords, users are required to verify
                        their identity using a second factor, such as a mobile verification code or
                        biometric data. The system also incorporates decentralized identity
                        management, which allows users to manage their credentials without relying
                        on a centralized authority, ensuring that identity information remains
                        private and secure.
                    </p>
                    <h3 id="5-2-network-security-protocols">5.2 Network Security Protocols</h3>
                    <p>
                        To protect the XOneFi network from unauthorized access and cyberattacks, a
                        series of robust security protocols are implemented. These protocols help
                        in maintaining the integrity and availability of the network while
                        safeguarding the confidentiality of user communications.
                    </p>
                    <p>
                        <strong>Per-Client Firewall Policy:</strong> Each device connecting to the
                        XOneFi network is assigned a per-client firewall policy. This policy is
                        enforced at the router level to ensure that unauthorized traﬀic is blocked
                        and that each client can only access resources they are permitted to use.
                        This approach provides individualized protection to each client, minimizing
                        the risk of lateral movement attacks within the network.
                    </p>
                    <p>
                        <strong>Dynamic Client Roster and Access Control:</strong> XOneFi maintains
                        a dynamic client roster that tracks all devices connected to the network in
                        real-time. This roster allows the system to continuously monitor and
                        validate client activity. If any unusual behavior or unauthorized device is
                        detected, the network can automatically revoke access and isolate the
                        compromised device to prevent the spread of threats. Additionally,
                        role-based access control (RBAC) is implemented to restrict access to
                        critical network components based on the user’s role, ensuring that only
                        authorized personnel can make modifications to the network.
                    </p>
                    <p>
                        <strong>Intrusion Detection and Prevention System (IDPS):</strong> The
                        XOneFi network (on XOneFi server) is protected by an Intrusion Detection and
                        Prevention System (IDPS) that actively monitors network traﬀic for
                        suspicious activity. The IDPS uses both signature-based and anomaly-based
                        detection techniques to identify known threats as well as zero-day attacks.
                        When a potential threat is detected, the system automatically takes action
                        to block the offending traﬀic and alert network administrators. This
                        real-time response helps in minimizing the impact of attacks and maintaining
                        network stability.
                    </p>
                    <p>
                        <strong>Secure Firmware Updates:</strong> The firmware used in XOneFi
                        routers is regularly updated to address vulnerabilities and enhance
                        functionality. Secure firmware updates are delivered over an encrypted
                        channel, and updates are digitally signed to ensure their authenticity. This
                        prevents malicious actors from intercepting or altering the firmware,
                        thereby reducing the risk of firmware-based attacks. The system also employs
                        automatic rollback to the previous version if any issues are detected during
                        an update. This ensures that routers remain operational and secure.
                    </p>
                    <p>
                        <strong>DDoS Protection:</strong> XOneFi server employs Distributed Denial
                        of Service (DDoS) protection mechanisms to ensure network availability
                        even in the face of large-scale attacks. The system uses traﬀic analysis
                        tools to identify and filter out malicious traﬀic, while legitimate user
                        traﬀic is prioritized. XOneFi also utilizes rate limiting and traﬀic shaping
                        techniques to control the flow of data and prevent the network from being
                        overwhelmed by large volumes of requests. This ensures that the network
                        remains responsive and accessible to legitimate users at all times.
                    </p>
                    <p>
                        <strong>End-to-End Traﬀic Encryption:</strong> All communication within the
                        XOneFi network is secured using end-to-end encryption protocols, including
                        TLS (Transport Layer Security). This ensures that data transmitted between
                        clients, routers, and backend servers remains confidential and cannot be
                        intercepted by unauthorized parties. In addition, XOneFi employs Perfect
                        Forward Secrecy (PFS) to ensure that even if a private key is compromised,
                        past sessions remain secure.
                    </p>
                    <p>
                        <strong>Physical Security Measures:</strong> Physical security is also an
                        important consideration for XOneFi routers, particularly in community
                        settings. Routers are equipped with tamper-evident seals to prevent
                        unauthorized physical access, and are designed to be securely mounted to
                        reduce the risk of theft. In case of physical tampering, routers are
                        programmed
                    </p>
                    <p>
                        to wipe sensitive configuration data to protect the security of the overall
                        network.
                    </p>
                    <h2 id="6-conclusion">6 Conclusion</h2>
                    <p>
                        XOneFi is built on the belief that high-quality Internet access is a
                        fundamental right that everyone should enjoy. The digital divide that
                        persists today creates significant inequalities, denying millions of people
                        the opportunity to benefit from the economic, educational, and social
                        advantages that reliable Internet access provides. By leveraging
                        decentralized wireless networks, blockchain technology, and cutting-edge
                        hardware and software, XOneFi aims to create a more inclusive and connected
                        world, where every community has the opportunity to participate in the
                        digital economy.
                    </p><br />
                    <p>
                        The core of XOneFi’s vision is to create a globally unified Internet network
                        that is owned and operated by the people. By enabling individuals, small
                        businesses, and community groups to become mini-ISPs, XOneFi empowers local
                        stakeholders to take control of their connectivity needs, creating a fairer
                        and more distributed network. This community-driven model reduces
                        dependency on traditional ISPs that often neglect underserved areas,
                        ensuring that even the most remote or economically challenged communities
                        can access high-speed, affordable Internet services.
                    </p><br />
                    <p>
                        XOneFi’s decentralized approach uses blockchain technology to tokenize
                        Internet connectivity, making it possible for individuals to share their
                        bandwidth and earn rewards through a transparent, secure, and equitable
                        system. By employing Ethereum smart contracts and Layer-2 scaling solutions,
                        XOneFi ensures that transactions are both cost-effective and scalable, and
                        allows users to participate without worrying about high fees or delays. The
                        integration of Low LEO satellites provides an additional layer of cover-
                        age, extending connectivity to areas where traditional infrastructure is
                        impractical or too costly to deploy.
                    </p><br />
                    <p>
                        The technical architecture of XOneFi, which includes customized routers,
                        advanced firmware, and a robust software ecosystem, is designed to be
                        accessible and easy to use for all participants. By allowing users to use
                        their own routers and configure them into XOneFi routers using the XOneFi
                        firmware, we ensure that participation is barrier-free, promoting widespread
                        adoption. The WiFi mesh networking component further enhances network
                        stability and coverage, ensuring seamless connectivity for all users.
                    </p><br />
                    <p>
                        XOneFi’s mission is to create a world where everyone has equal access to the
                        opportunities that the Internet provides. This mission is not something
                        that can be achieved alone—it requires the active participation of
                        stakeholders from all walks of life. We invite individuals, community
                        leaders, businesses, technology developers, and investors to join us in
                        realizing this vision. Whether you are looking to improve connectivity in
                        your local area, invest in a transformative technology, or contribute to
                        building the future of decentralized Internet, XOneFi offers an opportunity
                        to be part of something impactful and enduring.
                    </p><br />
                    <p>
                        By participating in XOneFi, stakeholders can play a direct role in bridging
                        the digital divide, empowering underserved communities, and ensuring that
                        the benefits of the Internet are accessible to everyone. The potential
                        economic, social, and educational impacts of this project are immense. By bringing equitable Internet access to all,
                        we can open up new opportunities for learning, entrepreneurship, healthcare,
                        and community engagement, ultimately driving positive change on a global
                        scale.
                    </p><br />
                    <p>
                        We believe that the Internet should be a shared resource—owned by the people
                        and accessible to all. Through XOneFi, we are building the infrastructure to
                        make this vision a reality. We invite you to join us on this journey to
                        create a truly inclusive and connected world. Together, we can bring
                        reliable Internet access to every corner of the globe and empower
                        individuals and communities to thrive in the digital age.
                    </p>
                </div>
            </div>
        </>

    );
};

export default Whitepaper;
