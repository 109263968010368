import React from "react";
/**
 *
 * @returns A Component for the rectangluar text boxes seen on the HowItWorks Screen
 */
function RectangleLogoAndText({ srcImage, headerText, bodyText }) {
  return (
    <div className="flex flex-row shadow-md shadow-gray-600 rounded-lg pl-5 pt-5 pb-6 pr-5">
      <img src={srcImage} alt="" />
      <div className="flex flex-col pl-8">
        <p className="text-2xl">{headerText}</p>
        <p className="text-gray-400">{bodyText}</p>
      </div>
    </div>
  );
}

export default RectangleLogoAndText;
